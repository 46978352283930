import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import TableOfContents from "../components/table-of-contents";
import { normalizeThemeUIColors, filterStyles } from "@lekoarts/gatsby-theme-specimens";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "specimens-for-design-systems"
    }}>{`Specimens for Design Systems`}</h1>
    <p>{`Leverage the wide variety of powerful React components of `}<a parentName="p" {...{
        "href": "https://github.com/LekoArts/gatsby-themes/tree/master/themes/gatsby-theme-specimens"
      }}>{`@lekoarts/gatsby-theme-specimens`}</a>{` to build your design system. Display your colors, typography or any other design tokens with ease and focus on the design system itself, not on how to showcase it. Works seamlessly with MDX.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/LekoArts/gatsby-starter-specimens"
      }}><strong parentName="a">{`Source code for this site`}</strong></a></p>
    <p>{`If you simply want to see how the components look, head over to the `}<a parentName="p" {...{
        "href": "/preview"
      }}><strong parentName="a">{`Preview`}</strong></a>{`!`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/LekoArts/gatsby-themes"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/github/stars/LekoArts/gatsby-themes?style=social",
          "alt": "GitHub stars"
        }}></img></a>{` `}<a parentName="p" {...{
        "href": "https://twitter.com/lekoarts_de"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/twitter/follow/lekoarts_de?label=Follow&style=social",
          "alt": "Twitter Follow"
        }}></img></a>{` `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/@lekoarts/gatsby-theme-specimens"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/npm/v/@lekoarts/gatsby-theme-specimens",
          "alt": "npm"
        }}></img></a>{` `}<a parentName="p" {...{
        "href": "https://github.com/LekoArts/gatsby-themes/blob/master/LICENSE"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/github/license/LekoArts/gatsby-themes",
          "alt": "License"
        }}></img></a></p>
    <p>{`Also be sure to checkout other `}<a parentName="p" {...{
        "href": "https://themes.lekoarts.de"
      }}>{`Free & Open Source Gatsby Themes`}</a></p>
    <h2 {...{
      "id": "features"
    }}>{`Features`}</h2>
    <ul>
      <li parentName="ul">{`Theme UI-based theming`}</li>
      <li parentName="ul">{`Suitable for MDX`}</li>
      <li parentName="ul">{`Offers React components specifically designed for design systems. You can display:`}<ul parentName="li">
          <li parentName="ul">{`Colors as swatches and rows. Individually placed or automated from an object/array in your theme file`}</li>
          <li parentName="ul">{`Typography e.g. font-family, font-size, font-weight and headings`}</li>
          <li parentName="ul">{`Spacing scales`}</li>
          <li parentName="ul">{`Audio files and downloads`}</li>
          <li parentName="ul">{`border-radius or box-shadow`}</li>
          <li parentName="ul">{`Alerts to emphasize important messages`}</li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm install @lekoarts/gatsby-theme-specimens
`}</code></pre>
    <p>{`And in your gatsby-config.js:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// gatsby-config.js
module.exports = {
  plugins: [
    {
      resolve: \`@lekoarts/gatsby-theme-specimens\`,
      options: {}
      }
    }
  ]
}
`}</code></pre>
    <p>{`View the `}<a parentName="p" {...{
        "href": "https://github.com/LekoArts/gatsby-themes/tree/master/themes/gatsby-theme-specimens"
      }}><strong parentName="a">{`theme's README`}</strong></a>{` to see more instructions on how to set up this theme!`}</p>
    <h2 {...{
      "id": "specimens"
    }}>{`Specimens`}</h2>
    <TableOfContents mdxType="TableOfContents" />
    <p><strong parentName="p">{`Please note:`}</strong>{` Components wrapped in a `}<inlineCode parentName="p">{`render()`}</inlineCode>{` don't need to be written this way in your MDX files - it's only necessary for `}<a parentName="p" {...{
        "href": "https://github.com/FormidableLabs/react-live#liveprovider-"
      }}><inlineCode parentName="a">{`react-live`}</inlineCode></a>{` demos on this page.`}</p>
    <h3 {...{
      "id": "alert"
    }}>{`Alert`}</h3>
    <p>{`Variants: `}<inlineCode parentName="p">{`alerts.success`}</inlineCode>{`, `}<inlineCode parentName="p">{`alerts.hint`}</inlineCode>{`, `}<inlineCode parentName="p">{`alerts.warning`}</inlineCode>{`, `}<inlineCode parentName="p">{`alerts.info`}</inlineCode>{`, `}<inlineCode parentName="p">{`alerts.danger`}</inlineCode>{`,`}</p>
    <p>{`Available props:`}</p>
    <ul>
      <li parentName="ul">{`type (string) (optional) (Default: "hint")`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Alert>Neutral hint - Default</Alert>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live noInline",
        "live": true,
        "noInline": true
      }}>{`render(
  <React.Fragment>
    <Alert type="success">Make it so!</Alert>
    <Alert type="hint">Neutral Hint</Alert>
    <Alert type="warning">Gentle warning :)</Alert>
    <Alert type="info">Super helpful information goes here</Alert>
    <Alert type="danger">nooooooooo, not this way</Alert>
  </React.Fragment>
);
`}</code></pre>
    <h3 {...{
      "id": "audio"
    }}>{`Audio`}</h3>
    <p>{`Variant: `}<inlineCode parentName="p">{`audio.specimens`}</inlineCode></p>
    <p>{`Available props:`}</p>
    <ul>
      <li parentName="ul">{`src (string) - Must be a valid path on your webserver, e.g. the sound file below is placed inside src/static/sounds`}</li>
      <li parentName="ul">{`name (string) (optional) (Default: "")`}</li>
      <li parentName="ul">{`desc (string) (optional) (Default: "")`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Audio
  src="sounds/through_the_gate.mp3"
  name="Through the Gate"
  desc="Hundreds of years in the future, in a colonized Solar System, police detective Josephus Miller, born on Ceres in the asteroid belt, is sent to find a missing young woman, Juliette 'Julie' Andromeda Mao. James Holden, Executive Officer of the ice hauler Canterbury, is involved in a tragic incident that threatens to destabilize the uneasy peace between Earth, Mars and the Belt."
/>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Audio src="sounds/through_the_gate.mp3" />
`}</code></pre>
    <h3 {...{
      "id": "border-radius"
    }}>{`Border Radius`}</h3>
    <p>{`Variant: `}<inlineCode parentName="p">{`tables.borderRadius`}</inlineCode></p>
    <p>{`Available props:`}</p>
    <ul>
      <li parentName="ul">{`radii`}</li>
    </ul>
    <p>{`radii has to be in the following format:`}</p>
    <pre><code parentName="pre" {...{}}>{`radii: {
  [key: string]: string
}
`}</code></pre>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live noInline",
        "live": true,
        "noInline": true
      }}>{`const radii = {
  none: "0",
  sm: "0.5rem",
  md: "1rem",
};

render(<BorderRadius radii={radii} />);
`}</code></pre>
    <h3 {...{
      "id": "color-row"
    }}>{`Color Row`}</h3>
    <p>{`Variant: `}<inlineCode parentName="p">{`rows.specimens`}</inlineCode></p>
    <p>{`Available props:`}</p>
    <ul>
      <li parentName="ul">{`color (string) - Must be in HEX format`}</li>
      <li parentName="ul">{`name (string)`}</li>
      <li parentName="ul">{`prefix (string) (optional) (Default: "")`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<ColorRow color="#000" name="Blackness" prefix="Dark - " />
`}</code></pre>
    <h3 {...{
      "id": "color-swatch"
    }}>{`Color Swatch`}</h3>
    <p>{`Variant: `}<inlineCode parentName="p">{`swatches.specimens`}</inlineCode></p>
    <p>{`Available props:`}</p>
    <ul>
      <li parentName="ul">{`color (string) - Must be in HEX format`}</li>
      <li parentName="ul">{`name (string) (optional) (Default: "")`}</li>
      <li parentName="ul">{`minimal (boolean) (optional) (Default: false)`}</li>
      <li parentName="ul">{`prefix (string) (optional) (Default: "")`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live noInline",
        "live": true,
        "noInline": true
      }}>{`render(
  <React.Fragment>
    <ColorSwatch color="#fff" />
    <ColorSwatch color="#2d3748" name="Blueish" />
    <ColorSwatch color="#667eea" name="Swift" minimal />
    <ColorSwatch color="#4fd1c5" name="4" prefix="Teal - " />
  </React.Fragment>
);
`}</code></pre>
    <h3 {...{
      "id": "download"
    }}>{`Download`}</h3>
    <p>{`Variant: `}<inlineCode parentName="p">{`download.specimens`}</inlineCode></p>
    <p>{`Available props:`}</p>
    <ul>
      <li parentName="ul">{`src (string) - Must be a valid path on your webserver, e.g. the file below is placed inside src/static/downloads`}</li>
      <li parentName="ul">{`name (string)`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Download
  name="Gatsby Themes Wallpaper"
  notes="Use for Social Media previews"
  src="downloads/gatsby-themes-wallpaper.jpg"
/>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Download name="Logo" bg="black" src="downloads/logo.png" />
`}</code></pre>
    <h3 {...{
      "id": "font-family"
    }}>{`Font Family`}</h3>
    <p>{`Variant: `}<inlineCode parentName="p">{`typography.fontFamily`}</inlineCode></p>
    <p>{`Available props:`}</p>
    <ul>
      <li parentName="ul">{`fonts`}</li>
      <li parentName="ul">{`previewText (string) (optional) - Replace "The quick brown fox jumps over the lazy dog" with something custom`}</li>
    </ul>
    <p>{`fonts has to be in the following format:`}</p>
    <pre><code parentName="pre" {...{}}>{`fonts: {
  [key: string]: string
}
`}</code></pre>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live noInline",
        "live": true,
        "noInline": true
      }}>{`const fonts = {
  sans:
    '-apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  serif: 'Georgia, Cambria, "Times New Roman", Times, serif',
  mono: 'Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
};

render(<FontFamily fonts={fonts} />);
`}</code></pre>
    <h3 {...{
      "id": "font-size"
    }}>{`Font Size`}</h3>
    <p>{`Variant: `}<inlineCode parentName="p">{`typography.fontSize`}</inlineCode></p>
    <p>{`Available props:`}</p>
    <ul>
      <li parentName="ul">{`fontSizes`}</li>
    </ul>
    <p>{`fontSizes has to be in the following format:`}</p>
    <pre><code parentName="pre" {...{}}>{`fontSizes: string[] | number[]
`}</code></pre>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live noInline",
        "live": true,
        "noInline": true
      }}>{`const fontSizes = ["0.875rem", "1rem", "1.25rem", "1.5rem"];

render(<FontSize fontSizes={fontSizes} />);
`}</code></pre>
    <h3 {...{
      "id": "font-weight"
    }}>{`Font Weight`}</h3>
    <p>{`Variant: `}<inlineCode parentName="p">{`typography.fontWeight`}</inlineCode></p>
    <p>{`Available props:`}</p>
    <ul>
      <li parentName="ul">{`fontWeights`}</li>
      <li parentName="ul">{`previewText (string) (optional) - Replace "The quick brown fox jumps over the lazy dog" with something custom`}</li>
    </ul>
    <p>{`fontWeights has to be in the following format:`}</p>
    <pre><code parentName="pre" {...{}}>{`fontWeights: {
  [key: string]: string
}
`}</code></pre>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live noInline",
        "live": true,
        "noInline": true
      }}>{`const fontWeights = {
  normal: "400",
  medium: "500",
  semibold: "600",
  bold: "700",
  black: "900",
};

render(<FontWeight fontWeights={fontWeights} />);
`}</code></pre>
    <h3 {...{
      "id": "heading"
    }}>{`Heading`}</h3>
    <p>{`Variants: `}<inlineCode parentName="p">{`typography.heading`}</inlineCode>{`, `}<inlineCode parentName="p">{`codeStyles.default`}</inlineCode></p>
    <p>{`Available props:`}</p>
    <ul>
      <li parentName="ul">{`styles`}</li>
      <li parentName="ul">{`theme`}</li>
      <li parentName="ul">{`previewText (string) (optional) - Replace "Heading" with something custom`}</li>
    </ul>
    <p>{`If you're already using Theme UI your theme file already has all necessary keys (styles, fontSizes, fontWeights, fonts). As you can see in the below example the `}<inlineCode parentName="p">{`theme.styles`}</inlineCode>{` has entries like `}<inlineCode parentName="p">{`h1`}</inlineCode>{` which then will be used to display.`}</p>
    <p>{`You can filter which keys to show by using the helper function `}<inlineCode parentName="p">{`filterStyles({ input, allowed })`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live noInline",
        "live": true,
        "noInline": true
      }}>{`const theme = {
  fontSizes: [
    "0.875rem",
    "1rem",
    "1.25rem",
    "1.5rem",
    "1.875rem",
    "2.25rem",
    "3rem",
    "4rem",
    "4.5rem",
  ],
  lineHeights: {
    heading: "1.25",
  },
  fontWeights: {
    heading: "700",
  },
  fonts: {
    heading: "inherit",
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    a: {
      color: "primary",
      textDecoration: "none",
      ":hover": {
        textDecoration: "underline",
      },
    },
    h1: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      m: 0,
      mb: 1,
      fontSize: 6,
      mt: 2,
    },
    h2: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      m: 0,
      mb: 1,
      fontSize: 5,
      mt: 2,
    },
    code: {},
    pre: {},
    hr: {
      bg: "muted",
      border: 0,
      height: "1px",
      m: 3,
    },
  },
};

render(
  <Heading
    styles={filterStyles({
      input: theme.styles,
      allowed: [\`h1\`, \`h2\`],
    })}
    theme={theme}
  />
);
`}</code></pre>
    <h3 {...{
      "id": "palette-color-rows"
    }}>{`Palette: Color Rows`}</h3>
    <p>{`Available props:`}</p>
    <ul>
      <li parentName="ul">{`colors - If you use Theme UI you can use the helper function `}<inlineCode parentName="li">{`normalizeThemeUIColors({ colors })`}</inlineCode>{` to convert the array of color strings to the necessary format`}</li>
      <li parentName="ul">{`prefix (string) (optional) (Default: "")`}</li>
    </ul>
    <p>{`colors has to be in the following format:`}</p>
    <pre><code parentName="pre" {...{}}>{`colors: {
  name: string
  color: string
}[]
`}</code></pre>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live noInline",
        "live": true,
        "noInline": true
      }}>{`const theme = {
  colors: {
    indigo: [null, "#ebf4ff", "#c3dafe", "#a3bffa", "#7f9cf5", "#667eea"],
  },
};

const orange = [
  {
    name: "1",
    color: "#feebc8",
  },
  {
    name: "2",
    color: "#fbd38d",
  },
];

render(
  <React.Fragment>
    <Palette
      colors={normalizeThemeUIColors({ colors: theme.colors.indigo })}
      prefix="Indigo - "
    />
    <h4>Orange</h4>
    <Palette colors={orange} />
  </React.Fragment>
);
`}</code></pre>
    <h3 {...{
      "id": "palette-color-swatches"
    }}>{`Palette: Color Swatches`}</h3>
    <p>{`Available props:`}</p>
    <ul>
      <li parentName="ul">{`colors - If you use Theme UI you can use the helper function `}<inlineCode parentName="li">{`normalizeThemeUIColors({ colors })`}</inlineCode>{` to convert the array of color strings to the necessary format`}</li>
      <li parentName="ul">{`prefix (string) (optional) (Default: "")`}</li>
      <li parentName="ul">{`single (boolean) (optional) (Default: false) - When passing in the colors object you can filter out all keys that have arrays as children`}</li>
      <li parentName="ul">{`minimal (boolean) (optional) (Default: false) - Hide RGB and CMYK label`}</li>
      <li parentName="ul">{`mode (string) (optional) (Default: "list")`}</li>
    </ul>
    <p>{`colors has to be in the following format:`}</p>
    <pre><code parentName="pre" {...{}}>{`colors: {
  name: string
  color: string
}[]
`}</code></pre>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live noInline",
        "live": true,
        "noInline": true
      }}>{`const theme = {
  colors: {
    primary: \`#2b6cb0\`,
    secondary: \`#feb2b2\`,
    success: \`#9ae6b4\`,
    text: \`#2d3748\`,
    indigo: [null, "#ebf4ff", "#c3dafe", "#a3bffa", "#7f9cf5", "#667eea"],
  },
};

render(
  <Palette
    colors={normalizeThemeUIColors({ colors: theme.colors })}
    minimal
    mode="swatch"
  />
);
`}</code></pre>
    <h3 {...{
      "id": "sizes"
    }}>{`Sizes`}</h3>
    <p>{`Variant: `}<inlineCode parentName="p">{`tables.space`}</inlineCode></p>
    <p>{`This theme also exposes a `}<inlineCode parentName="p">{`Table`}</inlineCode>{` component. It is used in this case to showcase the different `}<inlineCode parentName="p">{`sizes`}</inlineCode>{` of the theme. Generally speaking the `}<inlineCode parentName="p">{`Table`}</inlineCode>{` component has the following props:`}</p>
    <ul>
      <li parentName="ul">{`columns (string[])`}</li>
      <li parentName="ul">{`titles (string[])`}</li>
      <li parentName="ul">{`children (React.ReactNode)`}</li>
    </ul>
    <p>{`The `}<inlineCode parentName="p">{`columns`}</inlineCode>{` prop is defining the `}<inlineCode parentName="p">{`grid-template-columns`}</inlineCode>{` of the table in the fashion of Theme UI (`}<a parentName="p" {...{
        "href": "https://theme-ui.com/getting-started#responsive-styles"
      }}>{`Responsive Styles`}</a>{`). The `}<inlineCode parentName="p">{`titles`}</inlineCode>{` should be the same count as columns.`}</p>
    <p>{`So you can define the style for every breakpoint, e.g. `}<inlineCode parentName="p">{`[\`120px 1fr\`, \`150px 1fr\`]`}</inlineCode>{` (120px for everything below the smallest breakpoint, 150px for everything above smallest breakpoint).`}</p>
    <p>{`You can format the data you want to show how you like but in the end you should have the amount of columns as defined above.`}</p>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live noInline",
        "live": true,
        "noInline": true
      }}>{`const sizes = {
  "0": "0",
  "1": "0.25rem",
  "2": "0.5rem",
  "3": "0.75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "8": "2rem",
  "10": "2.5rem",
  "12": "3rem",
  "16": "4rem",
};

render(
  <Table
    columns={[\`120px 1fr\`]}
    titles={[\`Token\`, \`Value\`]}
    className="sizes-table"
  >
    {Object.entries(sizes).map(([key, value]) => (
      <div key={key}>
        <div>{key}</div>
        <div>{value}</div>
      </div>
    ))}
  </Table>
);
`}</code></pre>
    <h3 {...{
      "id": "shadows"
    }}>{`Shadows`}</h3>
    <p>{`Variant: `}<inlineCode parentName="p">{`tables.shadow`}</inlineCode></p>
    <p>{`Available props:`}</p>
    <ul>
      <li parentName="ul">{`shadows`}</li>
    </ul>
    <p>{`shadows has to be in the following format:`}</p>
    <pre><code parentName="pre" {...{}}>{`shadows: {
  [key: string]: string
}
`}</code></pre>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live noInline",
        "live": true,
        "noInline": true
      }}>{`const shadows = {
  default: \`0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)\`,
  xl: \`0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)\`,
  "2xl": \`0 25px 50px -12px rgba(0, 0, 0, 0.25)\`,
};

render(<Shadow shadows={shadows} />);
`}</code></pre>
    <h3 {...{
      "id": "space"
    }}>{`Space`}</h3>
    <p>{`Available props:`}</p>
    <ul>
      <li parentName="ul">{`space`}</li>
    </ul>
    <p>{`space has to be in the following format:`}</p>
    <pre><code parentName="pre" {...{}}>{`space: string[] | number[]
`}</code></pre>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live noInline",
        "live": true,
        "noInline": true
      }}>{`const space = [0, \`0.25rem\`, \`0.5rem\`, \`1rem\`, \`2rem\`, \`4rem\`, \`8rem\`];

render(<Space space={space} />);
`}</code></pre>
    <h3 {...{
      "id": "video"
    }}>{`Video`}</h3>
    <p>{`Variant: `}<inlineCode parentName="p">{`video.specimens`}</inlineCode></p>
    <p>{`Available props:`}</p>
    <ul>
      <li parentName="ul">{`src (string) - Must be a valid path on your webserver, e.g. the video file below is placed inside src/static/videos`}</li>
      <li parentName="ul">{`poster (string) (optional) (Default: "") - By default the video tag will use the first frame of the video as a preview. With the poster you can define a custom preview image`}</li>
      <li parentName="ul">{`name (string) (optional) (Default: "")`}</li>
      <li parentName="ul">{`muted (boolean) (optional) (Default: false)`}</li>
      <li parentName="ul">{`loop (boolean) (optional) (Default: false)`}</li>
      <li parentName="ul">{`autoplay (boolean) (optional) (Default: false)`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Video
  name="LekoArts Intro"
  src="videos/intro.mp4"
  poster="videos/intro_poster.jpg"
/>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      